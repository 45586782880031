import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import loadable from '@loadable/component';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { cryptoTheme } from 'common/src/theme/crypto';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Crypto/crypto.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';

import Banner from '../containers/Crypto/BannerSection';
import MagazineLogos from '../containers/Crypto/MagazineLogos';
import MagazineLogosCopy from '../containers/Crypto/MagazineLogosCopy';
import ContactSectionRedux from '../containers/Crypto/AntForm';
//import CompareHelocOptions from '../containers/Crypto/CompareHelocOptions';
//import Testimonial from '../containers/Crypto/Testimonial';
import AppVideo from '../containers/Crypto/AppVideo';
import BetaSection from '../containers/Crypto/BetaSectionEdit';
import LoanCard from '../containers/Crypto/MortgageDataCard';
import ControlSections from '../containers/Crypto/ControlSection';
import SEO from '../components/seo';
import store from '../store';
import BuilderPageComponent from '../containers/Crypto/BuilderIOComponents/PageComponent';

const Navbar = loadable(() => import('../containers/Crypto/Navbar'));
const TestimonialSlider = loadable(() =>
  import('../containers/Crypto/TestimonialSlider')
);
const CompareHelocOptions = loadable(() =>
  import('../containers/Crypto/CompareHelocCashOut')
);
const Transactions = loadable(() => import('../containers/Crypto/Transaction'));
const Reviews = loadable(() => import('../containers/Crypto/ReviewPage'));
const MillionsServed = loadable(() =>
  import('../containers/Crypto/MillionsServedHomepage')
);
const Footer = loadable(() => import('../containers/Crypto/Footer'));

const App = () => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <Provider store={store}>
        <Fragment>
          <SEO title="Best Finance - America's most flexible mortgage provider" />
          <ResetCSS />

          <GlobalStyle />
          <ContentWrapper>
            <Sticky
              top={0}
              innerZ={9999}
              inactiveClass="sticky-nav-active"
              activeClass="sticky-nav-active"
            >
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            {/*   <BuilderPageComponent content="/" /> */}
            <Banner />
            <MillionsServed />
            <LoanCard />
            {/*   <TestimonialSlider /> */}
            <Transactions />
            <Reviews />
            <CompareHelocOptions />
            {/*   <LandingPageButtons /> */}
            {/*  <MagazineLogos /> */}
            {/* <MagazineLogosCopy /> */}

            {/*   <ControlSections /> */}

            {/* <AppVideo /> */}
            {/* <BetaSection/> */}

            {/* <Testimonial /> */}

            <Footer />
          </ContentWrapper>
        </Fragment>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
